






















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Details from '@/components/catalog/Details.vue'

export default defineComponent({
  name: 'ExpertDetails',
  components: {
    Details,
  },
  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const { segment } = ctx.root.$route.params

    return {
      content,
      segment,
    }
  },
})
