





























































































































































































































































































import { defineComponent, ref, onBeforeMount } from '@vue/composition-api'
import { CatalogDetails } from '@/inc/types'

import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'CatalogDetails',
  props: {
    content: {
      type: Object as () => CatalogDetails,
      required: true,
    },
  },
  setup(props) {
    const { segment } = useGetters(['segment'])

    const canCopy = ref(false)
    const copied = ref(false)

    const copyToClipboard = (slug: string) => {
      const toCopy = `${window.location.origin}${window.location.pathname}${slug}`
      console.log('toCopy', toCopy)
      navigator.clipboard
        .writeText(toCopy)
        .then(() => {
          copied.value = true

          window.addEventListener('click', () => (copied.value = false), {
            once: true,
          })
          console.log('clipboard success')
        })
        .catch(() => {
          console.log('clipboard failed')
        })
    }

    onBeforeMount(() => {
      if (navigator.clipboard) {
        canCopy.value = true
      }
    })

    const a = props.content.contact.address
    const fullAddress = `${a.street} - ${a.postcode} ${a.city}`

    const isFullDesc = ref(false)
    const formatDescription = (desc: string, maxChars: number) =>
      desc.length > maxChars && !isFullDesc.value
        ? `${desc.substr(0, maxChars - 3)}...`
        : desc

    const seeMore = () => {
      isFullDesc.value = !isFullDesc.value
      console.log('SeeMore', isFullDesc.value)
    }

    return {
      isFullDesc,
      formatDescription,
      seeMore,
      fullAddress,
      segment,
      copyToClipboard,
      canCopy,
      copied,
    }
  },
})
